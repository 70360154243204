import React from 'react'
import PropTypes from 'prop-types'

import PaginationItem from './pagination-item'
import PaginationItemNext from './pagination-item-next'
import PaginationItemPrevious from './pagination-item-previous'

const Pagination = ({ to, currentPage, perPage, total, justifyContent }) => (
  <nav aria-label='Page navigation'>
    <ul
      className={`pagination pagination-sm justify-content-${justifyContent}`}
    >
      <PaginationItemPrevious to={to} currentPage={currentPage} />

      {getPageItems(to, currentPage, perPage, total)}

      <PaginationItemNext
        to={to}
        currentPage={currentPage}
        lastPage={calculateNumberOfPages(perPage, total)}
      />
    </ul>
  </nav>
)

const calculateNumberOfPages = (perPage, total) => Math.ceil(total / perPage)

const getPageItems = (to, currentPage, perPage, total) => {
  let pageItems = []
  let numberOfPages = calculateNumberOfPages(perPage, total)

  for (let n = 1; n <= numberOfPages; n++) {
    pageItems.push(
      <PaginationItem to={to} page={n} currentPage={currentPage} key={n} />,
    )
  }

  return pageItems
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  justifyContent: PropTypes.oneOf([
    'around',
    'between',
    'center',
    'end',
    'start',
  ]),
  perPage: PropTypes.number,
  to: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

Pagination.defaultProps = {
  currentPage: 1,
  justifyContent: 'center',
  perPage: 8,
}

export default Pagination
