import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const PaginationItem = ({ to, page, currentPage }) =>
  page === currentPage ? (
    <li className='page-item active' aria-current='page'>
      <span className='page-link'>
        {page}
        <span className='sr-only'>(current)</span>
      </span>
    </li>
  ) : (
    <li className='page-item'>
      <Link to={`${to}${page > 1 ? page : ''}`} className='page-link'>
        {page}
      </Link>
    </li>
  )

PaginationItem.propTypes = {
  currentPage: PropTypes.number,
  page: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
}

PaginationItem.defaultProps = {
  currentPage: 1,
}

export default PaginationItem
