import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const PaginationItemPrevious = ({ to, currentPage }) => {
  if (currentPage > 1) {
    to += currentPage > 2 ? currentPage - 1 : ''

    return (
      <li className='page-item'>
        <Link to={to} className='page-link' aria-label='Previous'>
          <span aria-hidden='true'>&laquo;</span>
        </Link>
      </li>
    )
  }

  return (
    <li className='page-item disabled'>
      <span className='page-link' aria-hidden='true' aria-label='Previous'>
        &laquo;
      </span>
    </li>
  )
}

PaginationItemPrevious.propTypes = {
  currentPage: PropTypes.number,
  to: PropTypes.string.isRequired,
}

PaginationItemPrevious.defaultProps = {
  currentPage: 1,
}

export default PaginationItemPrevious
