import React from 'react'
import { DateTime } from 'luxon'
import { Link } from 'gatsby'

import Time from '../components/time'

const NewsItem = ({ node }) => (
  <article className='col-12 col-md-6 col-lg-3 d-flex flex-column mb-4'>
    <h3 className='h5 text-danger'>{node.frontmatter.title}</h3>
    <h4 className='h6 text-muted'>
      <small>
        <Time time={node.frontmatter.date} format={DateTime.DATE_HUGE} />
      </small>
    </h4>
    <p>{node.excerpt}</p>
    <Link
      to={node.fields.slug}
      className='btn btn-sm btn-warning mt-auto stretched-link'
    >
      Read More
    </Link>
  </article>
)

export default NewsItem
