import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const PaginationItemNext = ({ to, currentPage, lastPage }) =>
  currentPage < lastPage ? (
    <li className='page-item'>
      <Link
        to={`${to}${currentPage + 1}`}
        className='page-link'
        aria-label='Next'
      >
        <span aria-hidden='true'>&raquo;</span>
      </Link>
    </li>
  ) : (
    <li className='page-item disabled'>
      <span className='page-link' aria-hidden='true' aria-label='Next'>
        &raquo;
      </span>
    </li>
  )

PaginationItemNext.propTypes = {
  currentPage: PropTypes.number,
  lastPage: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
}

PaginationItemNext.defaultProps = {
  currentPage: 1,
}

export default PaginationItemNext
