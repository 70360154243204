import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import Breadcrumb from '../components/breadcrumb/breadcrumb'
import NewsItem from '../components/news-item'
import PageHeader from '../components/layout/page-header'
import Pagination from '../components/pagination/pagination'
import SEO from '../components/seo'

const NewsList = ({ data, pageContext }) => {
  const currentPage = pageContext.currentPage
  const posts = data.allMarkdownRemark.edges
  const total = data.allMarkdownRemark.totalCount

  return (
    <>
      <SEO title='News' />

      <Layout>
        <Breadcrumb activeItem='News' />

        <PageHeader>News</PageHeader>

        <div className='container'>
          <div className='row'>
            {posts.map(({ node }) => (
              <NewsItem node={node} key={node.id} />
            ))}
          </div>

          <Pagination to='/news/' currentPage={currentPage} total={total} />
        </div>
      </Layout>
    </>
  )
}

export default NewsList

export const newsListQuery = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "news" } } }
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`
